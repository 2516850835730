import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
export let constRouter = [

]
export let userRouter = [{
        path: '/',
        name: 'index',
        alias: '/index/index',
        component: () =>
            import ('@/views/index/index'),
        meta: { title: '工会报名', index: 1 }
    },
    {
        path: '/index/mineIndex',
        name: 'mineIndex',

        component: () =>
            import ('@/views/index/mineIndex'),
        meta: { title: '工会报名', index: 1 }
    },
    {
        path: '/index/activing',
        name: 'activing',

        component: () =>
            import ('@/views/index/activing'),
        meta: { title: '活动报名', index: 1 }
    },
    {
        path: '/index/goods',
        name: 'goods',

        component: () =>
            import ('@/views/index/goods'),
        meta: { title: '福利商城', index: 1 }
    },
    {
        path: '/lador/ladorLogin',
        name: 'ladorLogin',

        component: () =>
            import ('@/views/lador/ladorLogin'),
        meta: { title: '个人中心', index: 1 }
    },
    {
        path: '/audit/index',
        name: 'audit',
        component: () =>
            import ('@/views/audit/index'),
        meta: { title: '审核记录', index: 1 }
    },
    {
        path: '/lador/index',
        name: 'lador',
        component: () =>
            import ('@/views/lador/index'),
        meta: { title: '工会报名', index: 1 }
    }, {
        path: '/lador/agreement',
        name: 'agreement',
        component: () =>
            import ('@/views/lador/agreement'),
        meta: { title: '入会申请', index: 1 }
    }, {
        path: '/lador/InformationForm',
        name: 'InformationForm',
        component: () =>
            import ('@/views/lador/InformationForm'),
        meta: { title: '工会报名信息填写', index: 1 }
    }, {
        path: '/lador/signature',
        name: 'signature',
        component: () =>
            import ('@/views/lador/signature'),
        meta: { title: '电子签名', index: 1 }
    }, {
        path: '/lador/material',
        name: 'material',
        component: () =>
            import ('@/views/lador/material'),
        meta: { title: '工会报名信息填写', index: 1 }
    }, {
        path: '/lador/pay',
        name: 'pay',
        component: () =>
            import ('@/views/lador/pay'),
        meta: { title: '支付页面', index: 1 }
    }, {
        path: '/lador/orderDetail',
        name: 'orderDetail',
        component: () =>
            import ('@/views/lador/orderDetail'),
        meta: { title: '流程状态', index: 1 }
    }, {
        path: '/lador/modification',
        name: 'modification',
        component: () =>
            import ('@/views/lador/modification'),
        meta: { title: '工会报名信息修改', index: 1 }
    },
    {
        path: '/lador/auditDetails',
        name: 'auditDetails',
        component: () =>
            import ('@/views/lador/auditDetails'),
        meta: { title: '审核页面', index: 1 }
    },
    {
        path: '/shareholders/index',
        name: 'shareholders',
        component: () =>
            import ('@/views/shareholders/index'),
        meta: { title: '股东报名', index: 1 }
    },
    {
        path: '/shareholders/contract',
        name: 'contract',
        component: () =>
            import ('@/views/shareholders/contract'),
        meta: { title: '合同填写', index: 1 }
    }, {
        path: '/shareholders/offline/index',
        name: 'offline',
        component: () =>
            import ('@/views/shareholders/offline/index'),
        meta: { title: '线下股东申请', index: 1 }
    }, {
        path: '/shareholders/offline/flow',
        name: 'flow',
        component: () =>
            import ('@/views/shareholders/offline/flow'),
        meta: { title: '流程状态', index: 1 }
    },
    {
        path: '/shareholders/online/index',
        name: 'online',
        component: () =>
            import ('@/views/shareholders/online/index'),
        meta: { title: '线上股东申请', index: 1 }
    },
    {
        path: '/shareholders/online/onlineDetails',
        name: 'onlineDetails',
        component: () =>
            import ('@/views/shareholders/online/onlineDetails'),
        meta: { title: '流程状态', index: 1 }
    },
    {
        path: '/audit/audit_activate',
        name: 'audit_activate',
        component: () =>
            import ('@/views/audit/audit_activate'),
        meta: { title: '身份激活', index: 1 }
    },
    {
        path: '/index/activate',
        name: 'activate',
        component: () =>
            import ('@/views/index/activate'),
        meta: { title: '身份激活', index: 1 }
    },
    {
        path: '/shareholders/activate/details',
        name: 'details',
        component: () =>
            import ('@/views/shareholders/activate/details'),
        meta: { title: '身份激活', index: 1 }
    },
    {
        path: '/shareholders/activate/index',
        name: 'activateIndex',
        component: () =>
            import ('@/views/shareholders/activate/index'),
        meta: { title: '身份激活', index: 1 }
    }, {
        path: '/shareholders/activate/pattern',
        name: 'pattern',
        component: () =>
            import ('@/views/shareholders/activate/pattern'),
        meta: { title: '身份激活', index: 1 }
    },
    {
        path: '/audit/audit_share',
        name: 'audit_share',
        component: () =>
            import ('@/views/audit/audit_share'),
        meta: { title: '审核页面', index: 1 }
    }, {
        path: '/index/share',
        name: 'share',
        component: () =>
            import ('@/views/index/share'),
        meta: { title: '登录', index: 1 }
    }, {
        path: '/shareholders/rule',
        name: 'rule',
        component: () =>
            import ('@/views/shareholders/rule'),
        meta: { title: '协议', index: 1 }
    }, {
        path: '/deal/tradingCenter',
        name: 'tradingCenter',
        component: () =>
            import ('@/views/deal/tradingCenter'),
        meta: { title: '转让中心', index: 1 }
    }, {
        path: '/deal/deal_list',
        name: 'deal_list',
        component: () =>
            import ('@/views/deal/deal_list'),
        meta: { title: '转让中心', index: 1 }
    }, {
        path: '/deal/anagement',
        name: 'anagement',
        component: () =>
            import ('@/views/deal/anagement'),
        meta: { title: '售卖管理', index: 1 }
    }, {
        path: '/deal/putaway',
        name: 'putaway',
        component: () =>
            import ('@/views/deal/putaway'),
        meta: { title: '售卖管理', index: 1 }
    }, {
        path: '/deal/order_list',
        name: 'order_list',
        component: () =>
            import ('@/views/deal/order_list'),
        meta: { title: '售卖管理', index: 1 }
    }, 
    // {
    //     path: '/deal/mine',
    //     name: 'mine',
    //     component: () =>
    //         import ('@/views/deal/mine'),
    //     meta: { title: '个人中心', index: 1 }
    // }, 
    // 新的个人中心
    {
        path: '/personalData/mine',
        name: 'mine',
        component: () =>
            import ('@/views/personalData/mine'),
        meta: { title: '个人中心', index: 1 }
    }, 
    // 个人信息
    {
        path: '/personalData/information',
        name: 'information',
        component: () =>
            import ('@/views/personalData/information'),
        meta: { title: '个人信息', index: 1 }
    }, 
    // 服务站点
    {
        path: '/personalData/serveSite',
        name: 'serveSite',
        component: () =>
            import ('@/views/personalData/serveSite'),
        meta: { title: '服务站点', index: 1 }
    }, 
    // 注册or延期
    {
        path: '/newLogin/application',
        name: 'application',
        component: () =>
            import ('@/views/newLogin/application'),
        meta: { title: '个人信息', index: 1 }
    },
    // 注册or延期申请成功
    {
        path: '/newLogin/applicationSuccess',
        name: 'applicationSuccess',
        component: () =>
            import ('@/views/newLogin/applicationSuccess'),
        meta: { title: '个人信息', index: 1 }
    }, 
    // 兑换时长
    {
        path: '/newLogin/exchangeTime',
        name: 'exchangeTime',
        component: () =>
            import ('@/views/newLogin/exchangeTime'),
        meta: { title: '兑换时长', index: 1 }
    },
    // 兑换明细
    {
        path: '/newLogin/exchangeDetails',
        name: 'exchangeDetails',
        component: () =>
            import ('@/views/newLogin/exchangeDetails'),
        meta: { title: '兑换明细', index: 1 }
    },
    // 电子证件照生成
    {
        path: '/document/documentForm',
        name: 'documentForm',
        component: () =>
            import ('@/views/document/documentForm'),
        meta: { title: '电子证件照生成', index: 1 }
    }, 
    // 我的电子证件
    {
        path: '/document/index',
        name: 'documentIndex',
        component: () =>
            import ('@/views/document/index'),
        meta: { title: '电子证件照生成', index: 1 }
    }, 





    {
        path: '/deal/return',
        name: 'return',
        component: () =>
            import ('@/views/deal/return'),
        meta: { title: '退款记录', index: 1 }
    }, {
        path: '/deal/message',
        name: 'message',
        component: () =>
            import ('@/views/deal/message'),
        meta: { title: '股份明细', index: 1 }
    }, {
        path: '/deal/payInput',
        name: 'payInput',
        component: () =>
            import ('@/views/deal/payInput'),
        meta: { title: '填写信息', index: 1 }
    }, {
        path: '/deal/pay',
        name: 'dealpay',
        component: () =>
            import ('@/views/deal/pay'),
        meta: { title: '支付', index: 1 }
    }, {
        path: '/deal/messages',
        name: 'messages',
        component: () =>
            import ('@/views/deal/messages'),
        meta: { title: '信息填写', index: 1 }
    }, {
        path: '/conversion/index',
        name: 'conversion',
        component: () =>
            import ('@/views/conversion/index'),
        meta: { title: '增值股份', index: 1 }
    }, {
        path: '/conversion/present',
        name: 'present',
        component: () =>
            import ('@/views/conversion/present'),
        meta: { title: '增值股份', index: 1 }
    }, {
        path: '/earnings/index',
        name: 'earningsindex',
        component: () =>
            import ('@/views/earnings/index'),
        meta: { title: '收益中心', index: 1 }
    }, {
        path: '/earnings/earning_form',
        name: 'earning_form',
        component: () =>
            import ('@/views/earnings/earning_form'),
        meta: { title: '收益中心', index: 1 }
    },{
        path: '/deal/payorder',
        name: 'payorder',
        component: () =>
            import ('@/views/deal/payorder'),
        meta: { title: '订单详情', index: 1 }
    },{
        path: '/conversion/orderDetails',
        name: 'orderDetails',
        component: () =>
            import ('@/views/conversion/orderDetails'),
        meta: { title: '兑换详情', index: 1 }
    },{
        path: '/deal/sellorder',
        name: 'sellorder',
        component: () =>
            import ('@/views/deal/sellorder'),
        meta: { title: '订单详情', index: 1 }
    },{
        path: '/deal/description',
        name: 'description',
        component: () =>
            import ('@/views/deal/description'),
        meta: { title: '转让说明', index: 1 }
    },{
        path: '/index/conversion',
        name: 'conversions',
        component: () =>
            import ('@/views/index/conversion'),
        meta: { title: '登录', index: 1 }
    },{
        path: '/index/detail',
        name: 'details',
        component: () =>
            import ('@/views/index/detail'),
        meta: { title: '登录', index: 1 }
    },{
        path: '/index/deal',
        name: 'deal',
        component: () =>
            import ('@/views/index/deal'),
        meta: { title: '登录', index: 1 }
    },{
        path: '/deal/contract',
        name: 'contract',
        component: () =>
            import ('@/views/deal/contract'),
        meta: { title: '居家协议', index: 1 }
    },{
        path: '/deal/confirmation',
        name: 'confirmation',
        component: () =>
            import ('@/views/deal/confirmation'),
        meta: { title: '信息修改', index: 1 }
    },{
        path: '/deal/freight',
        name: 'freight',
        component: () =>
            import ('@/views/deal/freight'),
        meta: { title: '运费支付', index: 1 }
    },{
        path: '/deal/abnormal',
        name: 'abnormal',
        component: () =>
            import ('@/views/deal/abnormal'),
        meta: { title: '异常反馈', index: 1 }
    },{
        path: '/deal/moneyConfirm',
        name: 'moneyConfirm',
        component: () =>
            import ('@/views/deal/moneyConfirm'),
        meta: { title: '款项确认', index: 1 }
    },{
        path: '/deal/returnDetail',
        name: 'returnDetail',
        component: () =>
            import ('@/views/deal/returnDetail'),
        meta: { title: '退款详情', index: 1 }
    },{
        path: '/deal/applytext',
        name: 'applytext',
        component: () =>
            import ('@/views/deal/applytext'),
        meta: { title: '申请书', index: 1 }
    },{
        path: '/expenditure/vipUser',
        name: 'vipUser',
        component: () =>
            import ('@/views/expenditure/vipUser/index'),
        meta: { title: '授权中心', index: 1 }
    },{
        path: '/expenditure/vipUser/contract',
        name: 'contracts',
        component: () =>
            import ('@/views/expenditure/vipUser/contract'),
        meta: { title: '合同续约', index: 1 }
    },{
        path: '/expenditure/vipUser/pay',
        name: 'payE',
        component: () =>
            import ('@/views/expenditure/vipUser/pay'),
        meta: { title: '支付费用', index: 1 }
    },{
        path: '/address',
        name: 'address',
        component: () =>
            import ('@/views/address/index'),
        meta: { title: '地址管理', index: 1 }
    },{
        path: '/address/detail',
        name: 'detail',
        component: () =>
            import ('@/views/address/detail'),
        meta: { title: '编辑地址', index: 1 }
    },{
        path: '/expenditure/history',
        name: 'historys',
        component: () =>
            import ('@/views//expenditure/history'),
        meta: { title: '历史记录', index: 1 }
    },{
        path: '/expenditure/g_history',
        name: 'g_historys',
        component: () =>
            import ('@/views//expenditure/g_history'),
        meta: { title: '合同历史记录', index: 1 }
    },{
        path: '/expenditure/histroyDetail',
        name: 'histroyDetail',
        component: () =>
            import ('@/views/expenditure/histroyDetail'),
        meta: { title: '历史记录', index: 1 }
    },{
        path: '/invoice/index',
        name: 'invoice',
        component: () =>
            import ('@/views/invoice/index'),
        meta: { title: '发票管理', index: 1 }
    },{
        path: '/invoice/outAnInvoice',
        name: 'outAnInvoice',
        component: () =>
            import ('@/views/invoice/outAnInvoice'),
        meta: { title: '发票记录', index: 1 }
    },{
        path: '/vipuser/index',
        name: 'vipuser',
        component: () =>
            import ('@/views/vipuser/index'),
        meta: { title: '福利专区', index: 1 }
    },{
        path: '/vipuser/goodsDetails',
        name: 'goodsDetails',
        component: () =>
            import ('@/views/vipuser/goodsDetails'),
        meta: { title: '限时专区', index: 1 }
    },{
        path: '/vipuser/confimOrder',
        name: 'confimOrder',
        component: () =>
            import ('@/views/vipuser/confimOrder'),
        meta: { title: '确认订单', index: 1 }
    },{
        path: '/vipuser/buyOrder',
        name: 'buyOrder',
        component: () =>
            import ('@/views/vipuser/buyOrder'),
        meta: { title: '订单详情', index: 1 }
    },{
        path: '/vipuser/orderList',
        name: 'orderList',
        component: () =>
            import ('@/views/vipuser/orderList'),
        meta: { title: '订单列表', index: 1 }
    },{
        path: '/vipuser/orderDetail',
        name: 'orderDetail',
        component: () =>
            import ('@/views/vipuser/orderDetail'),
        meta: { title: '订单详情', index: 1 }
    },{
        path: '/activity/index',
        name: 'activity',
        component: () =>
            import ('@/views/activity/index'),
        meta: { title: '活动报名', index: 1 }
    },{
        path: '/activity/apply',
        name: 'apply',
        component: () =>
            import ('@/views/activity/apply'),
        meta: { title: '活动报名', index: 1 }
    },
    {
        path: '/activity/map',
        name: 'map',
        component: () =>
            import ('@/views/activity/map'),
        meta: { title: '地图', index: 1 }
    },
    {
        path: '/activity/success',
        name: 'success',
        component: () =>
            import ('@/views/activity/success'),
        meta: { title: '报名成功', index: 1 }
    },
    {
        path: '/activity/activityList',
        name: 'activityList',
        component: () =>
            import ('@/views/activity/activityList'),
        meta: { title: '活动列表', index: 1 }
    },
    {
        path: '/activity/activityDetails',
        name: 'activityDetails',
        component: () =>
            import ('@/views/activity/activityDetails'),
        meta: { title: '活动详情', index: 1 }
    },
    {
        path: '/activity/activityDetails',
        name: 'activityDetails',
        component: () =>
            import ('@/views/activity/activityDetails'),
        meta: { title: '活动详情', index: 1 }
    },
    {
        path: '/conversion/pay',
        name: 'pays',
        component: () =>
            import ('@/views/conversion/pay'),
        meta: { title: '活动详情', index: 1 }
    },{
        path: '/materialCenter/index',
        name: 'materialCenter',
        component: () =>
            import ('@/views/materialCenter/index'),
        meta: { title: '物料中心', index: 1 }
    },{
        path: '/materialCenter/shipmentsList',
        name: 'shipmentsList',
        component: () =>
            import ('@/views/materialCenter/shipmentsList'),
        meta: { title: '物流信息', index: 1 }
    },{
        path: '/alteration/index',
        name: 'alteration',
        component: () =>
            import ('@/views/alteration/index'),
        meta: { title: '物料变更', index: 1 }
    },{
        path: '/alteration/Information',
        name: 'Information',
        component: () =>
            import ('@/views/alteration/Information'),
        meta: { title: '信息填写', index: 1 }
    },{
        path: '/alteration/addressFrom',
        name: 'addressFrom',
        component: () =>
            import ('@/views/alteration/addressFrom'),
        meta: { title: '信息填写', index: 1 }
    },{
        path: '/alteration/nextAudit',
        name: 'nextAudit',
        component: () =>
            import ('@/views/alteration/nextAudit'),
        meta: { title: '处理进度', index: 1 }
    },{
        path: '/alteration/nextAuditNew',
        name: 'nextAuditNew',
        component: () =>
            import ('@/views/alteration/nextAuditNew'),
        meta: { title: '处理进度', index: 1 }
    },{
        path: '/alteration/logistics',
        name: 'logistics',
        component: () =>
            import ('@/views/alteration/logistics'),
        meta: { title: '处理进度', index: 1 }
    },{
        path: '/alteration/fromList',
        name: 'fromList',
        component: () =>
            import ('@/views/alteration/fromList'),
        meta: { title: '审核记录', index: 1 }
    },{
        path: '/lador/service',
        name: 'service',
        component: () =>
            import ('@/views/lador/service'),
        meta: { title: '客服', index: 1 }
    },{
        path: '/workpoints/index',
        name: 'workpoints',
        component: () =>
            import ('@/views/workpoints/index'),
        meta: { title: '企业积分', index: 1 }
    },{
        path: '/workpoints/replacement',
        name: 'replacement',
        component: () =>
            import ('@/views/workpoints/replacement'),
        meta: { title: '置换工分', index: 1 }
    },{
        path: '/workpoints/selectOrder',
        name: 'selectOrder',
        component: () =>
            import ('@/views/workpoints/selectOrder'),
        meta: { title: '订单选择', index: 1 }
    },{
        path: '/workpoints/enterprise',
        name: 'enterprise',
        component: () =>
            import ('@/views/workpoints/enterprise'),
        meta: { title: '企业工分', index: 1 }
    },{
        path: '/alteration/selectAlteration',
        name: 'selectAlteration',
        component: () =>
            import ('@/views/alteration/selectAlteration'),
        meta: { title: '企业工分', index: 1 }
    },{
        path: '/alteration/orderDiscount',
        name: 'orderDiscount',
        component: () =>
            import ('@/views/alteration/orderDiscount'),
        meta: { title: '订单选择', index: 1 }
    },{
        path: '/alteration/progress',
        name: 'progress',
        component: () =>
            import ('@/views/alteration/progress'),
        meta: { title: '处理状态', index: 1 }
    },{
        path: '/alteration/electronic',
        name: 'electronic',
        component: () =>
            import ('@/views/alteration/electronic'),
        meta: { title: '电子合同', index: 1 }
    },{
        path: '/alteration/optionContract',
        name: 'optionContract',
        component: () =>
            import ('@/views/alteration/optionContract'),
        meta: { title: '合同选择', index: 1 }
    },{
        path: '/alteration/applicationRecord',
        name: 'applicationRecord',
        component: () =>
            import ('@/views/alteration/applicationRecord'),
        meta: { title: '申请记录', index: 1 }
    },{
        path: '/alteration/material_details_s',
        name: 'material_details_s',
        component: () =>
            import ('@/views/alteration/material_details_s'),
        meta: { title: '实物物料详情', index: 1 }
    },{
        path: '/electronicContract/index',
        name: 'electronicContract',
        component: () =>
            import ('@/views//electronicContract/index'),
        meta: { title: '电子合同', index: 1 }
    },{
        path: '/workpoints/renew',
        name: 'renew',
        component: () =>
            import ('@/views//workpoints/renew'),
        meta: { title: '电子合同', index: 1 }
    },{
        path: '/storeAssist/index',
        name: 'storeAssist',
        component: () =>
            import ('@/views//storeAssist/index'),
        meta: { title: '门店助力贡献值兑换', index: 1 }
    },{
        path: '/storeAssist/selectOrder',
        name: 'selectOrder',
        component: () =>
            import ('@/views//storeAssist/selectOrder'),
        meta: { title: '助力贡献值选择', index: 1 }
    },{
        path: '/index/qrCode',
        name: 'qrCode',
        component: () =>
            import ('@/views//index/qrCode'),
        meta: { title: '', index: 1 }
    }
]


let RouterList = constRouter.concat(userRouter);
let router = new Router({
    mode: 'hash',
    base: "lador",
    routes: RouterList
})
export default router;