/*
 * @Description: html添加水印
 * @Author: zhangwen
 * @Date: 2024-09-19
 */

const globalCanvas = null;
const globalWaterMark = null;

// watermark 样式
let style = `
display: block;
overflow: hidden;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
background-repeat: repeat;
pointer-events: none;`;

const getDataUrl = ({
  font,
  fillStyle,
  textAlign,
  textBaseline,
  text,
  rotate = -20,
  id
}) => {
  font = font || "14px Nsimsun";
  fillStyle = fillStyle || "rgba(180, 180, 180, 0.5)";
  text = text || "";
  console.log(id)
  const canvas = globalCanvas || document.createElement("canvas");
  const pdfDom = document.getElementById(id);
  console.log(pdfDom,"pdfDom")
  canvas.width = pdfDom.offsetWidth / 2;
  canvas.height = (pdfDom.offsetWidth * 841.89) / 1184.56;
  const ctx = canvas.getContext("2d"); // 获取画布上下文

  ctx.rotate((rotate * Math.PI) / 180);
  ctx.font = font;
  ctx.fillStyle = fillStyle;
  ctx.textAlign = textAlign || "left";
  ctx.textBaseline = textBaseline || "middle";

  const textWidth = ctx.measureText(text).width;
  const textX = canvas.width / 2 - textWidth / 2; // 计算文本的X坐标，使其左边与画布中心对齐
  ctx.fillText(
    text,
    textX - rotatePoint(textX, canvas.height / 2, rotate),
    canvas.height / 2
  ); // 在画布中心画文字
  return canvas.toDataURL("image/png", 1); // 第二个参数为质量
};

const rotatePoint = (x, y, degrees) => {
  // 将角度转换为弧度
  var radians = degrees * (Math.PI / 180);
  // 原始点到原点的距离（半径）
  var r = Math.sqrt(x * x + y * y);
  // 原始点与正x轴之间的角度（弧度制）
  var theta = Math.atan2(y, x);
  // 应用旋转角度
  var theta_new = theta + radians; // 注意这里应该是逆时针旋转，所以直接相加即可
  // 计算新的x和y坐标
  var x_new = r * Math.cos(theta_new);
  //var y_new = r * Math.sin(theta_new);
  // 计算x轴的偏移距离
  var delta_x = x_new - x;
  return delta_x;
};
const setWaterMark = (el, binding) => {
  //const parentElement = el.parentElement
  const parentElement = el;
  // 获取对应的 canvas 画布相关的 base64 url
  const url = getDataUrl(binding);
  // 创建 waterMark 父元素
  const waterMark = globalWaterMark || document.createElement("div");
  waterMark.className = "water-mark"; // 方便自定义展示结果
  style = `${style}background-image: url(${url});`;
  waterMark.setAttribute("style", style);

  // 将对应图片的父容器作为定位元素
  parentElement.setAttribute("style", "position: relative;");

  // 将图片元素移动到 waterMark 中
  parentElement.appendChild(waterMark);
};

// 监听 DOM 变化
const createObserver = (el, binding) => {
  const waterMarkEl = el.parentElement.querySelector(".water-mark");

  const observer = new MutationObserver((mutationsList) => {
    if (mutationsList.length) {
      const { removedNodes, type, target } = mutationsList[0];
      const currStyle = waterMarkEl ? waterMarkEl.getAttribute("style") : "";
      // 证明被删除了
      if (removedNodes[0] === waterMarkEl) {
        observer.disconnect();
        // 重新添加水印，dom监听
        init(el, { value: binding });
      } else if (
        type === "attributes" &&
        target === waterMarkEl &&
        currStyle !== style
      ) {
        waterMarkEl.setAttribute("style", style);
      }
    }
  });

  observer.observe(el.parentElement, {
    childList: true,
    attributes: true,
    subtree: true,
  });
};

// 初始化
const init = (el, binding) => {
  // 设置水印
  setWaterMark(el, binding.value);
  // 启动监控
  createObserver(el, binding.value);
};

// 定义指令配置项
const directives = {
  inserted(el, binding) {
    init(el, binding);
  },
};

export default {
  name: "watermark",
  directives,
};
